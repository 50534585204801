import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import { bounds, getWinSize } from '@src/helper'


const Tooltip = ({ ID, position,  children, ariaLabel, logo}) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const [lock, setLock] = useState(false);
	
	

	const toolTipState = (e) => {
		
		if (e.keyCode === 27) {
			setLock(false);
			setShowTooltip(false);
			return;
		}

		if(e.type === 'click' && lock){
			setLock(false);
			setShowTooltip(false);
			return;
		}else if(e.type === 'click' && !lock){
			setLock(true);
			setShowTooltip(true);
			return;
		}

		if(e.type === 'mouseenter'){
			setShowTooltip(true);
			adjustTooltipPosition();
		}
		else if(e.type === 'mouseleave' && !lock){
			setShowTooltip(false);
		}
		
	};
	
	const adjustTooltipPosition = () =>{
		if (typeof window !== 'undefined') {
			const toolTipContent = document.getElementById(ID)
			const viewportSize = getWinSize();
			const targetBounds = bounds(toolTipContent);
			//adjust position if overflowing to the right
			if(targetBounds.right - viewportSize.width >= 0){
				toolTipContent.style.transform = 'translate(' + ( targetBounds.right - viewportSize.width + 10)*(-1) + 'px, 10%)'
			}
			//adjust position if overflowing to the left
			else if(targetBounds.left <= 10){
				toolTipContent.style.transform = 'translate(' + ( targetBounds.left)*(-1) + 'px, 10%)'
			}
			//default position if no overflow
			else{
				toolTipContent.style.transform = 'translate(' +  (toolTipContent.offsetWidth / 2)*(-1) + 'px), 10%)'
			}
			
		}
		
	}
	
	useEffect(()=>{
        adjustTooltipPosition();

        window.addEventListener("resize", adjustTooltipPosition);
        return () => {window.removeEventListener("resize", adjustTooltipPosition)};
	},[showTooltip])


    return (
        <div className="kf-tooltip">
			<button className="kf-tooltip__trigger" type="button" aria-label={ariaLabel} aria-describedby={ID} 				
				onMouseEnter={toolTipState}
				onMouseLeave={toolTipState}
				onKeyDown={toolTipState}
				onClick={toolTipState} >
				{logo}
			</button>
			<div className={"kf-tooltip__content"} id={ID} role="tooltip" aria-hidden={showTooltip} aria-expanded={showTooltip}> {/*eslint-disable-line no-use-before-define*/}
				{children}
			</div>
        </div>
	);
};

Tooltip.defaultProps = {
	children: null,
};

Tooltip.propTypes = {
	ID: PropTypes.string.isRequired,
	ariaLabel: PropTypes.string.isRequired,
	position: PropTypes.string,
	children: PropTypes.node,
};

export default Tooltip;
